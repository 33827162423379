import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Home from "./components/Home/Home";
import Courses from "./components/Courses/Courses";
import Course from "./components/Course/Course";
import MyCourse from "./components/MyCourse/MyCourse";
import Dashboard from './components/Dashboard/Dashboard';
import Quiz from './components/Quiz/Quiz';
import Community from './components/Community/Community';
import Consultations from './components/Consultation/Consultation';
import Consultationslp from './components/Consultation/Consultationlp';
import ConsultRegis from './components/Consultation/ConsultationRegis';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element= { <Home />} />
        <Route path='/courses' element= {<Courses/>}/>
        <Route path='/course' element= {<Course/>}/>
        <Route path='/my-course' element= {<MyCourse/>}/>
        <Route path='/community' element= {<Community/>}/>
        <Route path='/consultations' element= {<Consultations/>}/>
        <Route path='/consultationslp' element= {<Consultationslp/>}/>
        <Route path='/consultations/regis' element= {<ConsultRegis/>}/>
        <Route path='/dashboard' element= {<Dashboard/>}/>
        <Route path='/quiz' element= {<Quiz/>}/>
      </Routes>
    </Router>
    
  );
}

export default App;
