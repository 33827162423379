import React,{useState, useEffect}  from "react";
import { useNavigate } from "react-router-dom";
import { PopupMenu } from "react-simple-widgets";
import jwt_decode from "jwt-decode";

const Head = () => {
  const navigate = useNavigate();
  const [decoded,setDecode] = useState(null);

  function logout() {
    navigate("/");
    sessionStorage.clear()
  }

  useEffect(() => {
    async function setUserDecode(){
      try {
        const tokenString = sessionStorage.getItem('token');
        const userToken = JSON.parse(tokenString);
        const data = jwt_decode(userToken);
        setDecode(data)
      } catch (error) {
        logout()
      }
    }
    setUserDecode()
  }, []);

  if (!decoded) return ""

  return (
    <>
      <section className='head'>
        <div className='container flexSB'>
          <div className='logo'>
            <h1>Techservant</h1>
            <span>Get your weapons for the real tech worlds</span>
          </div>

          <div className='profile text-end'>
          <PopupMenu>
            <button className="btn btn-primary">
              <small>{decoded.given_name}</small>
              <i className="fa fa-chevron-down"></i>
            </button>

            <div className="card text-start">
              <div className="card-body px-4 py-4">
                <div id="circle-avatar" className="text-center mx-auto mb-4">
                  <span>{decoded.given_name.charAt(0)}</span>
                </div>

                <h5 className="text-center mb-0">{decoded.given_name}</h5>
                <p className="text-center mb-2">{decoded.email}</p>

                <hr />


                <div className="d-grid">
                  <button className="btn btn-secondary" onClick={logout}>
                    <small>Logout</small>
                  </button>
                </div>
              </div>
            </div>
          </PopupMenu>
            
            {/* <i className='fab fa-facebook-f icon'></i>
            <i className='fab fa-instagram icon'></i>
            <i className='fab fa-twitter icon'></i>
            <i className='fab fa-youtube icon'></i> */}
          </div>
        </div>
      </section>
    </>
  )
}

export default Head
