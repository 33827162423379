import React from "react"
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";
import ReactPixel from 'react-facebook-pixel';
import "./Dashboard.css";
import image1 from "../../assets/images/creativity-pana.svg"
import image2 from "../../assets/images/seminar-pana.svg"
import image3 from "../../assets/images/solidarity-pana.svg"
// import AboutCard from "../about/AboutCard"
// import Hblog from "./Hblog"
// import HAbout from "./HAbout"
// import Hero from "./hero/Hero"
// import Hprice from "./Hprice"
// import Testimonal from "./testimonal/Testimonal"

const Dashboard = () => {
    const navigate = useNavigate();
    const options = {
        autoConfig: true, 
        debug: false, 
    };
    ReactPixel.init('191657676990001', options);
    function toQuiz() {
        navigate("/quiz");
    }
    function toAllCourses() {
        navigate("/courses");
    }
    function toCommunity() {
        navigate("/community");
    }
    React.useEffect(() => {
      ReactPixel.pageView();
    }, []);
  return (
    <>
        <Header/>
        <div className="dashboard">
            <div className="column card-dashboard" onClick={()=>toQuiz()}>
                <img src={image1} className="image-center1"></img>
                <div className="text-card">Check Your Potential</div>
            </div>
            <div className="column card-dashboard" onClick={()=>toAllCourses()}>
                <img src={image2} className="image-center2"></img>
                <div className="text-card">All Courses</div>
            </div>
            <div className="column card-dashboard" onClick={()=>toCommunity()}>
                <img src={image3} className="image-center3"></img>
                <div className="text-card">Community</div>
            </div>
        </div>
      {/*<Hero />
        <AboutCard />
      <HAbout />
      <Testimonal />
      <Hblog />
      <Hprice /> */}
    </>
  )
}

export default Dashboard
