import React, {useState} from "react";
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import { useNavigate } from "react-router-dom";
import "./Consultation.css";
import jwt_decode from "jwt-decode";
import Select from 'react-select';
import Header from "../Header/Header";
import { FormatRupiah } from "@arismun/format-rupiah";
import image2 from "../../assets/images/seminar-pana.svg";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import logo from "../../assets/images/logo.ico";


const ConsultationRegis = () => {
    const baseApiURL = `https://master-api.techservant.id/api`;
    const baseFeURL = `http://localhost:3000`;
    const navigate = useNavigate();
    const pixel = '191657676990001';
    const viewContent = 'ViewContent';
    const addToCart = 'AddToCart';
    const initiateCheckout = 'InitiateCheckout';
    const [isLoading, setIsLoading] = React.useState(false);
    const [user_name, setName] = useState('');
    const [user_email, setEmail] = useState('');
    const [school, setSchool] = useState('');
    const [grade, setGrade] = useState('');
    const [city, setCity] = useState('');
    const [whatsapp, setWa] = useState('');
    const [gender, setGenderVal] = useState('M');
    const [genderLbl, setGenderLbl] = useState('Men');
    const consultation_id = 1;
    
    const getToken =() =>{
        const tokenString = sessionStorage.getItem('token')
        const userToken = JSON.parse(tokenString)
        return userToken
    } 
    const options = [
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' }
    ]
    
    const handleChange = (selectedOption) => {
        setGenderVal(selectedOption.value);
        setGenderLbl(selectedOption.label);
    };

    const submit = async () => {
        let err = false;
        if (user_name === ''){
            err=true
            alert('Nama Wajib Diisi !')
        }else if (school === ''){
            err=true
            alert('Sekolah / Kampus Wajib Diisi !')
        }else if (grade === ''){
            err=true
            alert('Grade Wajib Diisi !')
        }else if (city === ''){
            err=true
            alert('Umur Wajib Diisi !')
        }else if (gender === ''){
            err=true
            alert('Gender Wajib Diisi !')
        }else if (whatsapp === ''){
            err=true
            alert('Whatsapp Wajib Diisi !')
        }else if (user_email === ''){
            err=true
            alert('Email Wajib Diisi !')
        }
        if(!err){
            const auth = await getToken();
            async function regis(){
                axios.post(`${baseApiURL}/consultations/`,{
                    consultation_id, user_name, user_email, school, grade, gender, city, whatsapp
                },{
                    headers: {
                        'authorization': auth
                    }
                }).then((response) => {
                    window.open(`https://${response.data.link_payment}`);
                }).catch((error) => {
                    alert(error);
                });
            }
            await regis()
        }
    }

    function logout() {
        navigate("/");
        sessionStorage.clear()
    }

    function toDetail(id) {
        navigate("/course",{state:{id:id}});
    }

    React.useEffect(() => {
        setIsLoading(true);
        
        const options = {
            autoConfig: true, 
            debug: false, 
        };
        ReactPixel.init(pixel,options);
        ReactPixel.track(initiateCheckout);
        
        setIsLoading(false);
    }, []);

    if (isLoading){
        return (
            <>
                <LoadingSpinner />
            </>
        )
    } 

    return (
        <>
        <div className="consultation">
            <div className="logo-header">
                <img src={logo} className="logo-img left-column"></img>
                <h1 className="right-column">Techservant</h1>
                {/* <span>Get your weapons for the real tech worlds</span> */}
            </div>
            <div className="offer">
                <div className='myCoursesCard'>
                    <div className='container'>
                        <div className='items' >
                            <div className='content flex'>
                                
                                <div className='text'>
                                    <h1>1 on 1 Consultation</h1>
                                    <div className='rate'>
                                        <i className='fa fa-star'></i>
                                        <i className='fa fa-star'></i>
                                        <i className='fa fa-star'></i>
                                        <i className='fa fa-star'></i>
                                        <i className='fa fa-star'></i>
                                        <label htmlFor=''>(5.0)</label>
                                    </div>
                                    <div className='details'>
                                        <span>30 Minutes</span>
                                    </div>
                                </div>
                            </div>
                            <div className='price'>
                                <h4 className="crossed-text">
                                    <FormatRupiah value="249000"/>
                                </h4>
                                <h5 >
                                    <FormatRupiah value="49000"/>
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        <h5>Registration </h5>
        <div className="form">
            <div className="">
                <div className="col-md-12">
                    <label htmlFor="name">Nama :</label>
                    <input id="name" type="text" value={user_name} onChange={e => setName(e.target.value)}/>
                </div>
                <div className="col-md-12">
                    <label htmlFor="school">Sekolah / Kampus :</label>
                    <input type="text" id="school" name="school" value={school} onChange={e => setSchool(e.target.value)}></input>
                </div>
            </div>
            <div className="">
                <div className="col-md-12">
                    <label htmlFor="grade">Kelas (SMK/SMA) / Semester (Kuliah)</label>
                    <input type="text" id="grade" name="grade" value={grade} onChange={e => setGrade(e.target.value)}></input>
                </div>
                <div className="col-md-12">
                    <label htmlFor="city">Kota Tempat Tinggal</label>
                    <input type="text" id="city" name="city" value={city} onChange={e => setCity(e.target.value)}></input>
                </div>
            </div>
            <div className="">
                <div className="col-md-12">
                    <label htmlFor="gender">Gender</label>
                    <Select 
                        options={options} 
                        value={{label: gender}}
                        onChange={handleChange}
                    />
                </div>
                <div className="col-md-12">
                    <label htmlFor="whatsapp">Whatsapp</label>
                    <input type="text" id="whatsapp" name="whatsapp" value={whatsapp} onChange={e => setWa(e.target.value)}></input>
                </div>
            </div>
            <div className="">
                <div className="col-md-12">
                    <label htmlFor="user_email">Email</label>
                    <input type="text" id="user_email" name="user_email" value={user_email} onChange={e => setEmail(e.target.value)}></input>
                </div>
            </div>
            <div className="">
                <div className="col-md-12">
                    <button className="btn btn-comnty" onClick={()=>submit()}>
                        <small>Daftar Sekarang</small>
                    </button>
                </div>
            </div>
        </div>
        </div>
    </>
  );
}

export default ConsultationRegis