const quizData = {
  title: 'Cek Potensi Karir Kamu di Dunia Tech',
  description:
    'Berdasarkan pengalaman pribadi di dunia Tech belasan tahun, Tech Servant mencoba menggali potensi kamu sebagai Software Engineer',
  img: 'start-example.jpg',
  questions: [
    {
      id: 1,
      text: 'Kamu selalu memerhatikan tata letak dan kamu akan terganggu jika ada sesuatu yang tidak cocok dengan sekitarnya, seperti lukisan yang miring, handuk basah di atas kasur, dll',
      type: 'frontend',
      alternatives: [
        {
          id: 1,
          text: 'Sangat Tidak Cocok',
        },
        {
          id: 2,
          text: 'Lumayan Tidak Cocok',
        },
        {
          id: 3,
          text: 'Biasa Saja',
        },
        {
          id: 4,
          text: 'Lumayan Cocok',
        },
        {
          id: 5,
          text: 'Sangat Cocok',
        },
      ],
    },
    {
      id: 2,
      text: 'Kamu memerhatikan terhadap warna pakaian yang digunakan, apakah warna atasan cocok dengan warna bawahan',
      type: 'frontend',
      alternatives: [
        {
          id: 1,
          text: 'Sangat Tidak Cocok',
        },
        {
          id: 2,
          text: 'Lumayan Tidak Cocok',
        },
        {
          id: 3,
          text: 'Biasa Saja',
        },
        {
          id: 4,
          text: 'Lumayan Cocok',
        },
        {
          id: 5,
          text: 'Sangat Cocok',
        },
      ],
    },
    {
      id: 3,
      text: 'Kamu tertarik dengan cafe-cafe yang estetik',
      type: 'frontend',
      alternatives: [
        {
          id: 1,
          text: 'Sangat Tidak Cocok',
        },
        {
          id: 2,
          text: 'Lumayan Tidak Cocok',
        },
        {
          id: 3,
          text: 'Biasa Saja',
        },
        {
          id: 4,
          text: 'Lumayan Cocok',
        },
        {
          id: 5,
          text: 'Sangat Cocok',
        },
      ],
    },
    {
      id: 4,
      text: 'Jika ada masalah, maka kamu akan mencari tau secara spesifik masalahnya ada dimana',
      type: 'backend',
      alternatives: [
        {
          id: 1,
          text: 'Sangat Tidak Cocok',
        },
        {
          id: 2,
          text: 'Lumayan Tidak Cocok',
        },
        {
          id: 3,
          text: 'Biasa Saja',
        },
        {
          id: 4,
          text: 'Lumayan Cocok',
        },
        {
          id: 5,
          text: 'Sangat Cocok',
        },
      ],
    },
    {
      id: 5,
      text: 'Jika lupa menyimpan barang dimana, maka kamu akan merunut suatu kejadian dari awal sampai akhir berdasarkan waktu hilangnya',
      type: 'backend',
      alternatives: [
        {
          id: 1,
          text: 'Sangat Tidak Cocok',
        },
        {
          id: 2,
          text: 'Lumayan Tidak Cocok',
        },
        {
          id: 3,
          text: 'Biasa Saja',
        },
        {
          id: 4,
          text: 'Lumayan Cocok',
        },
        {
          id: 5,
          text: 'Sangat Cocok',
        },
      ],
    },
    {
      id: 6,
      text: 'Sangat tertarik dengan alur sebuah bisnis seperti apa, kemudian dapat menggambarkannya di atas kertas secara detail',
      type: 'backend',
      alternatives: [
        {
          id: 1,
          text: 'Sangat Tidak Cocok',
        },
        {
          id: 2,
          text: 'Lumayan Tidak Cocok',
        },
        {
          id: 3,
          text: 'Biasa Saja',
        },
        {
          id: 4,
          text: 'Lumayan Cocok',
        },
        {
          id: 5,
          text: 'Sangat Cocok',
        },
      ],
    },
    {
      id: 7,
      text: 'Tertarik dengan cara kerja suatu system yang pernah dilihat, seperti sebuah pesawat terbang, kemudian penasaran bagaimana cara kerja pesawat bisa terbang',
      type: 'devops',
      alternatives: [
        {
          id: 1,
          text: 'Sangat Tidak Cocok',
        },
        {
          id: 2,
          text: 'Lumayan Tidak Cocok',
        },
        {
          id: 3,
          text: 'Biasa Saja',
        },
        {
          id: 4,
          text: 'Lumayan Cocok',
        },
        {
          id: 5,
          text: 'Sangat Cocok',
        },
      ],
    },
    {
      id: 8,
      text: 'Jika ada teman kesulitan mengerjakan sesuatu, kamu akan membantu dia dengan caramu sendiri',
      type: 'devops',
      alternatives: [
        {
          id: 1,
          text: 'Sangat Tidak Cocok',
        },
        {
          id: 2,
          text: 'Lumayan Tidak Cocok',
        },
        {
          id: 3,
          text: 'Biasa Saja',
        },
        {
          id: 4,
          text: 'Lumayan Cocok',
        },
        {
          id: 5,
          text: 'Sangat Cocok',
        },
      ],
    },
    {
      id: 9,
      text: 'Saat kamu pergi jalan-jalan ke suatu tempat dengan teman-temanmu menggunakan sebuah mobil, kamu memikirkan jumlah maksimum orang yang bisa masuk ke mobil tersebut',
      type: 'devops',
      alternatives: [
        {
          id: 1,
          text: 'Sangat Tidak Cocok',
        },
        {
          id: 2,
          text: 'Lumayan Tidak Cocok',
        },
        {
          id: 3,
          text: 'Biasa Saja',
        },
        {
          id: 4,
          text: 'Lumayan Cocok',
        },
        {
          id: 5,
          text: 'Sangat Cocok',
        },
      ],
    },
  ],
  results: [
    {
      id: 1,
      range: {
        from: 10,
        to: 15,
      },
      title: 'Congratulations',
      img:'frontend.png',
      description:
        'Potensi terpendam kamu adalah sebagai Frontend',
    },
    {
      id: 2,
      range: {
        from: 10,
        to: 15,
      },
      title: 'Congratulations',
      img:'backend.png',
      description:
        'Potensi terpendam kamu adalah sebagai Backend',
    },
    {
      id: 3,
      range: {
        from: 10,
        to: 15,
      },
      title: 'Congratulations',
      description:
        'Potensi terpendam kamu adalah sebagai DevOps',
      img: 'devops.png',
    },
  ],
};

export default quizData;
