import React from "react";
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import { useNavigate } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import "./Home.css";
import { Modal, Button } from 'react-bootstrap';
import { GoogleLogin } from '@react-oauth/google';
import jwt_decode from "jwt-decode";

const Home = () => {
    const baseApiURL = `https://master-api.techservant.id/api`;
    const [isShow, invokeModal] = React.useState(false);
    const navigate = useNavigate();
    const advancedMatching = { em: 'some@email.com' }; 
    const options = {
        autoConfig: true, 
        debug: false, 
    };
    ReactPixel.init('191657676990001', options);

    const openModal = () => {
      return invokeModal(true)
    }
    const closeModal = () => {
      return invokeModal(false)
    }  
    const login = (credential) => {
        return axios.post(`${baseApiURL}/users/login`, {
            credential
        })
        .then((response) => {
            console.log(response.data);
            // Handle data
            saveToken(credential);
            closeModal();
            navigate("/dashboard");
        })
        .catch((error) => {
            console.log(error);
        })
    }
     
    const getToken =() =>{
        const tokenString = sessionStorage.getItem('token')
        const userToken = JSON.parse(tokenString)
        return userToken
    } 
    const [token, setToken] = React.useState(getToken())
    const saveToken = credential => {
        sessionStorage.setItem('token', JSON.stringify(credential))
        setToken(credential)
    }

    React.useEffect(() => {
        ReactPixel.pageView();
        if(getToken() != null){
            navigate('/dashboard')
        }
    }, []);
    
    return (
    <>
        <section className="home">
            <div className="container">
            <div className="row">
                <div id='heading'>
                    <h3>Welcome to Techservant</h3>
                    <h1>Siap membantu kamu untuk memasuki dunia teknologi yang sesungguhnya </h1>
                </div>
                <p>
                Mempersiapkan senjata kamu, from zero to hero
                </p>
                <div className="button">
                <button className="primary-btn" onClick={openModal}>
                    GET YOUR WEAPON NOW <i className="fa fa-long-arrow-alt-right"></i>
                </button>
                </div>
            </div>
            </div>
        </section>
        <div className="margin"></div>
        <Modal show={isShow}>
            <Modal.Header closeButton onClick={closeModal}>
                <Modal.Title>Login to Techservant</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <GoogleLogin
                onSuccess={credentialResponse => {
                    console.log(credentialResponse.credential)
                    const decoded = jwt_decode(credentialResponse.credential);
                    console.log(decoded);
                    login(credentialResponse.credential)
                }}
                onError={() => {
                    console.log('Login Failed');
                }}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={closeModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    </>
  );
}

export default Home
