import React, {useState, useEffect}  from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import jwt_decode from "jwt-decode";
import Select from 'react-select';
import Header from "../Header/Header";
import "./Community.css";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const Community = () => {
    const navigate = useNavigate();
    const baseApiURL = `https://master-api.techservant.id/api`;
    const [isRegistered, setRegister] = useState(false)
    const [isStatus, setStatus] = useState(false)
    const [groupCommunity, setGroupCommunity] = useState(null)
    const [name, setName] = useState('');
    const [school, setSchool] = useState('');
    const [grade, setGrade] = useState('');
    const [age, setAge] = useState('');
    const [genderVal, setGenderVal] = useState('M');
    const [genderLbl, setGenderLbl] = useState('Men');
    const [isLoading, setIsLoading] = React.useState(false);
    const title = `<h3>Hi, welcome to Our Community, Let's Join Us!</h3>`;
    const getToken =() =>{
        const tokenString = sessionStorage.getItem('token')
        const userToken = JSON.parse(tokenString)
        return userToken
    } 
    const options = [
        { value: 'M', label: 'Male' },
        { value: 'F', label: 'Female' }
      ]
      
    function logout() {
        navigate("/");
        sessionStorage.clear()
    }
    function goToLink(link){
        window.open(link, "_blank", "noreferrer");
    }
    const submit = async () => {
        let err = false;
        if (name === ''){
            err=true
            alert('Nama Wajib Diisi !')
        }else if (school === ''){
            err=true
            alert('Sekolah / Kampus Wajib Diisi !')
        }else if (grade === ''){
            err=true
            alert('Grade Wajib Diisi !')
        }else if (age === ''){
            err=true
            alert('Umur Wajib Diisi !')
        }else if (genderVal === ''){
            err=true
            alert('Gender Wajib Diisi !')
        }
        if(!err){
            const auth = await getToken();
            async function regis(){
                axios.post(`${baseApiURL}/community/`,{
                    name, school, grade, age, genderVal
                },{
                    headers: {
                        'authorization': auth
                    }
                }).then((response) => {
                    setRegister(true);
                }).catch((error) => {
                    if(error.response.status === 401){
                        alert(error);
                        logout();
                    };
                    alert(error);
                });
            }
            await regis()
        }
    }
    const handleChange = (selectedOption) => {
        setGenderVal(selectedOption.value);
        setGenderLbl(selectedOption.label);
    };
    const checkRegisteredData = () => {
        const auth = getToken();
        axios.get(`${baseApiURL}/community/`,{
            headers: {
                'authorization': auth
              }
        }).then((response) => {
            setRegister(true);
            if (response.data.status === 'A'){
                setStatus(true)
            }
            setIsLoading(false);
        }).catch((error) => {
            if(error.response.status === 401){
                logout();
            }else if(error.response.status === 404){
                console.log('404');
                setRegister(false);
                setIsLoading(false);
            }else{
                logout();
            }

        });
    }
    const fetchCommunityGroup = async () => {
        const auth = getToken();
        await axios.get(`${baseApiURL}/community/group`,{
            headers: {
                'authorization': auth
              }
        }).then((response) => {
            setGroupCommunity(response.data);
        }).catch((error) => {
            if(error.response.status === 401){
                logout();
            }
        });
    }
    useEffect(() => {
        setIsLoading(true);
        const auth = getToken();
        const user = jwt_decode(auth);
        const options = {
            autoConfig: true, 
            debug: false, 
        };
        const advancedMatching = { em: user.email };
        ReactPixel.init('191657676990001', advancedMatching,options);
        ReactPixel.track("ContentView");
        fetchCommunityGroup()
        checkRegisteredData()
    }, []);

    if (isLoading){
        return (
            <>
                <Header/>
                <LoadingSpinner />
            </>
        )
    }

    return (
    <>
        <Header/>
        <div className="community">
            <div style={isRegistered?{display:'none'}:{}}>
                <h5>Registrasi </h5>
                <div className="form">
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="name">Nama :</label>
                            <input id="name" type="text" value={name} onChange={e => setName(e.target.value)}/>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="school">Sekolah / Kampus :</label>
                            <input type="text" id="school" name="school" value={school} onChange={e => setSchool(e.target.value)}></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="grade">Kelas (SMK/SMA) / Semester (Kuliah)</label>
                            <input type="text" id="grade" name="grade" value={grade} onChange={e => setGrade(e.target.value)}></input>
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="age">Umur</label>
                            <input type="number" id="age" name="age" value={age} onChange={e => setAge(e.target.value)}></input>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <label htmlFor="gender">Gender</label>
                            <Select 
                                options={options} 
                                value={{label: genderVal}}
                                // onChange={e => setGender(e.target.value)}
                                onChange={handleChange}
                            />
                            {/* <select className="form-select" tabindex="0" aria-label="Gender" value={gender} onChange={e => setGender(e.target.value)}>
                            <option selected value="">Pilih</option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                            </select> */}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12">
                            <button className="btn btn-comnty" onClick={()=>submit()}>
                                <small>Go to Our Community</small>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div style={isRegistered?{}:{display:'none'}}>
                {
                    isStatus ? (
                        <h3>Hi, welcome to Our Community, Let's Join Us!</h3>
                    ):(
                        <div>
                            <h3>Need Approval </h3>
                            <p>One Step Closer to Join Our Community, please follow this step for join us !</p>
                        </div>
                    )
                }
                
                <div className="row">
                    {   
                        isStatus ? (
                            groupCommunity.map((val) => (
                                    <div className='col-md-4 card-community' key={val.id} onClick={()=>goToLink(val.link)}>
                                        <div >
                                            <p className="text">
                                                {val.name}
                                            </p> 
                                            <p className="join">
                                                Join Now !
                                            </p>
                                        </div>
                                        
                                    </div>
                                )
                            )
                        ) : (
                            <div className="h-100">
                                <div className='col-md-4 card-community ' >
                                    <span className="text-share">
                                        1. Screenshot dashboard kamu
                                    </span> 
                                </div>
                                <div className='col-md-4 card-community'>
                                    <p className="text-share">
                                        2. Share ke IG Story kamu dan mention @techservant.id
                                    </p> 
                                </div>
                                <div className='col-md-4 card-community' >
                                    <p className="text-share">
                                        3. Direct Message @techservant.id kalo kamu sudah share di IG Story kamu
                                    </p> 
                                </div>
                                <div className='col-md-4 card-community' >
                                    <p className="text-share">
                                        4. Tunggu approval dari admin
                                    </p> 
                                </div>
                            </div>
                        )
                    }
                </div>
                
            </div>
        </div>
        
    </>
  )
}

export default Community
