import React, {useState} from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import styles from './Result.module.scss';
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";

// import Button from '../../common/Button/Button';
// import ShareButtons from '../../common/ShareButtons/ShareButtons';

// import iconRight from '../../assets/images/arrow-return.svg';
import imageBackend from '../../assets/images/results/backend.png';
import imageFrontend from '../../assets/images/results/frontend.png';
import imageDevops from '../../assets/images/results/devops.png';
import imageDefault from '../../assets/images/results/default/default.jpg';

function Result({
  // quizTitle,
  // numCorrectAnswers,
  // numTotalQuestions,
  results,
  // currentUrl,
  // onClickRestart,
  numBackend,
  numFrontend,
  numDevops,
}) {
  // const shareUrl = currentUrl;
  // const shareMessage = `I got ${numCorrectAnswers} out of ${numTotalQuestions} correct questions in "${quizTitle}" quiz. Check it here: ${currentUrl}`;

  let chosenResult;

  const[frontend, backend, devops] = results;

  let score = 0;
  if (numBackend > score) {
    score = numBackend;
    chosenResult = backend;
  }
  if (numFrontend > score) {
    score = numFrontend;
    chosenResult = frontend;
  }
  if (numDevops > score) {
    score = numDevops;
    chosenResult = devops;
  }
  

  // results.map((result, i) => {
  //   console.log(`${result.id} - ${numBackend}`);

    
  //   if(result.id === 2 && (numBackend >= result.range.from && numBackend <= result.range.to)){
  //     chosenResult = results[i];
      
  //   }
  //   if(result.id === 1 && (numFrontend >= result.range.from && numFrontend <= result.range.to)){
  //     chosenResult = results[i];
  //   }
  //   if(result.id === 3 && (numDevops >= result.range.from && numDevops <= result.range.to)){
  //     chosenResult = results[i];
  //   }
  //   return false;
  // });

  let image = imageDefault;
  let position = '';
  if(chosenResult.id === 2){
    image = imageBackend;
    position = 'backend';
  }else if(chosenResult.id === 1){
    image = imageFrontend;
    position = 'frontend';
  }else if(chosenResult.id === 3){
    image = imageDevops;
    position = 'devops';
  }
  
  const tokenString = sessionStorage.getItem('token')
  const userToken = JSON.parse(tokenString)
  let decoded;
  decoded = jwt_decode(userToken);
  const email = decoded.email;

  axios.post('https://master-api.techservant.id/api/answers/', {
    position,score,email,
  })
  .then((response) => {
    console.log(response.data);
      // Handle data
  })
  .catch((error) => {
    console.log(error);
  })

  const navigate = useNavigate();
  function toDashboard() {
    navigate("/dashboard");
  }

  
    
  

  return (
    <div className={styles.result}>
      <div className={styles['result-header']}>
        {/* <div className={styles.numbers}>
          <strong>{numCorrectAnswers}</strong>
          <span>/</span>
          <span>{numTotalQuestions}</span>
        </div> */}

        <img
          alt="Result"
          src={image}
          className={styles['result-img']}
        />
      </div>

      <div className={styles.title}>
        <strong>{chosenResult.title}</strong>
      </div>

      <div className={styles.description}>{chosenResult.description}</div>

      <div className={styles['restart-button-container']}>
        <button className="btn btn-secondary" onClick={toDashboard}>
          <small>Back to Home</small>
        </button>
      </div>

      <div className={styles['share-message']}>Share hasilnya ke Insta Story dan ajak teman-teman kamu untuk mengetahui potensi terdalam mereka sebagai Software Engineer</div>
      {/* <div className={styles['share-buttons-container']}>
        <ShareButtons
          shareUrl={shareUrl}
          shareMessage={shareMessage}
          hashtags={['quiz', 'react']}
        />
      </div> */}
    </div>
  );
}

Result.propTypes = {
  // quizTitle: PropTypes.string.isRequired,
  // numCorrectAnswers: PropTypes.number.isRequired,
  // numTotalQuestions: PropTypes.number.isRequired,
  results: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      range: PropTypes.shape({
        from: PropTypes.number.isRequired,
        to: PropTypes.number.isRequired,
      }),
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      img: PropTypes.string,
    }).isRequired,
  ).isRequired,
  // currentUrl: PropTypes.string.isRequired,
  // onClickRestart: PropTypes.func,
  numBackend: PropTypes.number,
  numFrontend: PropTypes.number,
  numDevops: PropTypes.number,
};

export default Result;
