import React, {useState, useEffect} from "react";
import axios from 'axios';
import { useNavigate, useLocation } from "react-router-dom";
// import { useHistory } from "react-router-dom";
import ReactPlayer from 'react-player';
import "./Course.css";
import jwt_decode from "jwt-decode";
import ReactPixel from 'react-facebook-pixel';
import Header from "../Header/Header";
import { Modal, Button } from 'react-bootstrap';
import { FormatRupiah } from "@arismun/format-rupiah";
import image2 from "../../assets/images/seminar-pana.svg";
// import myVideo from "../../assets/media/videoplayback.mp4";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";


const Course = () => {
    const baseApiURL = `https://master-api.techservant.id/api`;
    const baseFeURL = `http://localhost:3000`;
    const location = useLocation();
    const activeBackground = '#1eb2a6';
    const activeFontColor = '#fff';
    const inActiveBackground = '#fff';
    const inActiveFontColor = '#000';
    const [modalIsShow, invokeModal] = React.useState(false);
    const [coursesChapter, setChapter] = useState(false);
    const [coursesDetail, setDetail] = useState(false);
    const [config, setConfig] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    let [myVideo, setMyVideo] = useState(null);
    let [idActive, setIdActive] = useState(0);
    const navigate = useNavigate();
    const courses_id = 1;

    const getToken =() =>{
        const tokenString = sessionStorage.getItem('token')
        const userToken = JSON.parse(tokenString)
        return userToken
    } 

    function logout() {
        navigate("/");
        sessionStorage.clear()
    }

    const openModal = () => {
        return invokeModal(true)
    }
    const closeModal = () => {
        return invokeModal(false)
    }

    const linkPayment = (link) => {
        window.open(`https://${link}`, "_blank", "noreferrer");
        return invokeModal(false)
    }

    const newTransaction = async (id) => {
        const auth = await getToken();
        async function createBill(id){
            const courses_id = id;
            axios.post(`${baseApiURL}/transactions/`,{
                courses_id
            },{
                headers: {
                    'authorization': auth
                  }
            }).then((response) => {
                window.open(`https://${response.data.link_payment}`, "_blank", "noreferrer");
            }).catch((error) => {
                if(error.response.status === 401){
                    logout();
                };
            });
        }
        await createBill(id)

        return invokeModal(false)
    }

    const fetchConfig = async () => {
        const auth = await getToken();
         await axios.get(`${baseApiURL}/courses/config/1`,{
            headers: {
                'authorization': auth
              }
        }).then((response) => {
            setConfig(response.data.description);
            fetchChapter();
        }).catch((error) => {
            if(error.response.status === 401){
                logout();
            };
            setDetail(false);
            setIsLoading(false);
        });
    }

    const fetchChapter = async () => {
        const auth = getToken();
        await axios.get(`${baseApiURL}/courses/chapter/${courses_id}`,{
            headers: {
                'authorization': auth
              }
        }).then((response) => {
            setChapter(response.data);
            for (let i = 0; i < response.data.length; i++) {
                console.log('first load sebelum if: ' + i);
                if (response.data[i].is_done == 0){
                    setMyVideo(response.data[i].video);
                    setIdActive(response.data[i].id);
                    break;
                }
            }
            fetchCourseDetail();
        }).catch((error) => {
            if(error.response.status === 401){
                logout();
            };
            setDetail(false);
            setIsLoading(false);
        });
    }

    const fetchCourseDetail = async () => {
        const auth = getToken();
        await axios.get(`${baseApiURL}/courses/${courses_id}`,{
            headers: {
                'authorization': auth
              }
        }).then((response) => {
            setDetail(response.data);
            setIsLoading(false);
        }).catch((error) => {
            if(error.response.status === 401){
                logout();
            };
            setDetail(false);
            setIsLoading(false);
        });
    }

    useEffect(() => {
        setIsLoading(true);
        const auth = getToken();
        const user = jwt_decode(auth);
        const options = {
            autoConfig: true, 
            debug: false, 
        };
        const advancedMatching = { em: user.email };
        ReactPixel.init('191657676990001', advancedMatching,options);
        ReactPixel.track("ContentView");
        fetchConfig();
    }, []);
    
    const [key, setKey] = useState(0);

    const changeVideo = (newUrl, idChapter) => {
        let dataChapter;
        for (let index = 0; index < coursesChapter.length; index++) {
            const element = coursesChapter[index];
            if (element.id == idChapter) {
                dataChapter = element
                break;
            }
            
        }
        // const dataChapter = coursesChapter.filter((chapter) => chapter.id === idChapter);
        console.log('data chapter:' + dataChapter.id);
        if(dataChapter.is_lock){
            console.log('data lock:' + dataChapter.id);
            openModal();
        }else{
            setMyVideo(newUrl);
            setKey(idChapter);
            setIdActive(idChapter);
        }
        
    };
    if (isLoading){
        return (
            <>
                <Header/>
                <LoadingSpinner />
            </>
        )
    } else {
        if (!coursesDetail || coursesDetail.length < 1) return (
            <>
                <Header/>
                <div className="courses">
                    <div className="announce">
                        {config}
                        <img src={image2} className="image-center2"></img>
                    </div>
                </div>
            </>
        )
    }

    return (
        <>
        <Header/>
        <div className="course">
            <div className="course-left">
                <div className='videoplay'>
                    <div className="film-box">
                        <div className='player-wrapper'>
                            <ReactPlayer 
                                className='player'
                                key={myVideo}
                                url={myVideo} 
                                controls = {true}
                                width='100%'
                                height='100%'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="course-right">
                <div className="title">
                    {coursesDetail.coursesName}
                </div>
                <div className="cta">
                    
                    {
                        coursesDetail.status === false ? 
                        (
                            <button className="btn btn-cta" onClick={()=>openModal()}>
                                <small>Unlock Your Weapon Now</small>
                            </button>
                        ) : (null)
                    }
                </div>
                <div className="chapter">
                {coursesChapter.map((val) => (
                    <div 
                        className="chapter-card" 
                        key={val.id} 
                            onClick={() => changeVideo(val.video,val.id)}
                        style={
                            {
                                backgroundColor: idActive === val.id ? activeBackground : inActiveBackground, 
                            }
                        }
                    >
                        <i 
                            className="fa fa-play-circle fa-lg desc-ico" 
                            aria-hidden="true"
                            style={
                                {
                                    color: idActive === val.id ? activeFontColor : inActiveFontColor,
                                }
                            }       
                            >
                        </i> 
                        <i 
                            className="desc"
                            style={
                                {
                                    color: idActive === val.id ? activeFontColor : inActiveFontColor,
                                    fontWeight: idActive === val.id ? 600 : 200,
                                }
                            }  
                        >
                            {val.name}
                        </i>
                        {
                           val.is_lock ? (<i className="fa fa-lock" aria-hidden="true"></i>) : (null)
                        }
                    </div>
                ))}
                </div>
            </div>
        </div>
        <Modal show={modalIsShow}>
            <Modal.Header closeButton onClick={closeModal}>
                <Modal.Title>Daftar Ecourse {coursesDetail.coursesName} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="courseCard">
                    <div className='content flex'>
                        <div className='left'>
                        <div className='img'>
                            <img src={coursesDetail.cover} alt='' />
                        </div>
                        </div>
                        <div className='text'>
                            <h1>{coursesDetail.coursesName}</h1>
                            <div className='rate'>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star'></i>
                                <i className='fa fa-star'></i>
                                <label htmlFor='star'>(5.0)</label>
                            </div>
                            <div className='details'>
                                <span>{coursesDetail.totalTime}</span>
                            </div>
                        </div>
                        
                    </div>
                    <div className='price'>
                        <h5>
                            <FormatRupiah value={coursesDetail.price}/>
                        </h5>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {
                    coursesDetail.status === false ? 
                    (
                        <button className="btn btn-cta" onClick={()=>linkPayment(coursesDetail.link_payment)}>
                            <small>One Step Closer, Link Payment Here</small>
                        </button>
                    ) : (
                        <button className="btn btn-cta" onClick={()=>newTransaction(coursesDetail.id)}>
                            <small>Unlock Your Weapon Here</small>
                        </button>
                    )
                }
                
            </Modal.Footer>
        </Modal>
    </>
  );
}

export default Course