import React, { useState } from "react"
import { Link, NavLink } from "react-router-dom"
import Head from "./Head"
import "./header.css"

const Header = () => {
  const [click, setClick] = useState(false)

  return (
    <>
      <Head />
      <header>
        <nav className='flexSB' id="navbar">
          <ul className={click ? "mobile-nav" : "flexSB "} onClick={() => setClick(false)}>
            <li>
              <NavLink className={({isActive}) => (isActive ? 'active' : null)} to='/dashboard'>Dashboard</NavLink>
            </li>
            <li>
              <NavLink className={({isActive}) => (isActive ? 'active' : null)} to='/my-course'>My Courses</NavLink>
            </li>
            <li>
              <NavLink className={({isActive}) => (isActive ? 'active' : null)} to='/courses'>All Courses</NavLink>
            </li>
            {/* <li>
              <NavLink className={({isActive}) => (isActive ? 'active' : null)} to='/consultations'>Consultations</NavLink>
            </li> */}
            <li>
              <NavLink className={({isActive}) => (isActive ? 'active' : null)} to='/community'>Our Community</NavLink>
            </li>
            {/* <li>
              <Link to='/pricing'>Pricing</Link>
            </li>
            <li>
              <Link to='/journal'>Journal</Link>
            </li>
            <li>
              <Link to='/contact'>Contact</Link>
            </li> */}
          </ul>
          <div className='start'>
            <div className='button'>GET YOUR WEAPONS</div>
          </div> 
          <button className='toggle' onClick={() => setClick(!click)}>
            {click ? (<i className='fa fa-times'> </i> ): (<i className='fa fa-bars'></i>)}
          </button>
        </nav>
      </header>
    </>
  )
}

export default Header
