import React from "react";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import "./MyCourse.css";
import jwt_decode from "jwt-decode";
import Header from "../Header/Header";
import { FormatRupiah } from "@arismun/format-rupiah";
import image2 from "../../assets/images/seminar-pana.svg";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";


const MyCourse = () => {
    const baseApiURL = `https://master-api.techservant.id/api`;
    const baseFeURL = `http://localhost:3000`;
    const navigate = useNavigate();
    const [config, setConfig] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    const getToken =() =>{
        const tokenString = sessionStorage.getItem('token')
        const userToken = JSON.parse(tokenString)
        return userToken
    } 
    

    function logout() {
        navigate("/");
        sessionStorage.clear()
    }

    function toDetail(id) {
        navigate("/course",{state:{id:id}});
    }

    const [coursesData, setPost] = React.useState(null);

    const fetchMyCourse = async () => {
        const auth = getToken();
        const user = jwt_decode(auth);
        await axios.get(`${baseApiURL}/courses/mycourse/${user.email}`,{
            headers: {
                'authorization': auth
              }
        }).then((response) => {
            setPost(response.data);
            setIsLoading(false);
        }).catch((error) => {
            if(error.response.status === 401){
                logout();
            };
        });
    }

    const fetchMyConfig = async () => {
        const auth = getToken();
        await axios.get(`${baseApiURL}/courses/config/2`,{
            headers: {
                'authorization': auth
              }
        }).then((response) => {
            setConfig(response.data.description);
            setIsLoading(false);
        }).catch((error) => {
            if(error.response.status === 401){
                logout();
            };
        });
    }

    React.useEffect(() => {
        setIsLoading(true);
        fetchMyCourse();
        fetchMyConfig();
        setIsLoading(false);
    }, []);

    if (isLoading){
        return (
            <>
                <Header/>
                <LoadingSpinner />
            </>
        )
    } else {

        if (!coursesData || coursesData.length < 1) return (
            <>
                <Header/>
                <div className="courses">
                    <div className="announce">
                        <img src={image2} className="my-image-center2"></img>
                        <span>{config}</span>
                    </div>
                </div>
            </>
        )
    }
    
    return (
        <>
        <Header/>
        <div className="my-courses">
            
        
            <section className='myCoursesCard'>
                <div className='container grid2'>
                {coursesData.map((val) => (
                    <div className='items' key={val.id}>
                    <div className='content flex'>
                        <div className='left'>
                        <div className='img'>
                            <img src={val.cover} alt='' />
                        </div>
                        </div>
                        <div className='text'>
                        <h1>{val.coursesName}</h1>
                        <div className='rate'>
                            <i className='fa fa-star'></i>
                            <i className='fa fa-star'></i>
                            <i className='fa fa-star'></i>
                            <i className='fa fa-star'></i>
                            <i className='fa fa-star'></i>
                            <label htmlFor=''>(5.0)</label>
                        </div>
                        <div className='details'>
                        <span>{val.totalTime}</span>
                        </div>
                        </div>
                    </div>
                    <div className='price'>
                        <h4>
                        <FormatRupiah value={val.price}/>
                        </h4>
                    </div>
                    <button className="btn btn-card" onClick={()=>toDetail(val.id)}>
                        <small> Train Now </small>
                    </button>
                    </div>
                ))}
                </div>
            </section>
        </div>
    </>
  );
}

export default MyCourse