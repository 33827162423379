import React from "react";
import axios from 'axios';
import ReactPixel from 'react-facebook-pixel';
import { useNavigate } from "react-router-dom";
import "./Consultation.css";
import jwt_decode from "jwt-decode";
import Header from "../Header/Header";
import { FormatRupiah } from "@arismun/format-rupiah";
import image1 from "../../assets/images/bg4.jpg";
import logo from "../../assets/images/logo.ico";
import potential from "../../assets/images/potential.jpg";
import evtech from "../../assets/images/evtech.jpg";
import curlevel from "../../assets/images/curlevel.jpg";
import roadmap from "../../assets/images/roadmap.jpg";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";


const Consultationlp = () => {
    const baseApiURL = `https://master-api.techservant.id/api`;
    const baseFeURL = `http://localhost:3000`;
    const pixel = '191657676990001';
    const viewContent = 'ViewContent';
    const addToCart = 'AddToCart';
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = React.useState(false);
    
    const getToken =() =>{
        const tokenString = sessionStorage.getItem('token')
        const userToken = JSON.parse(tokenString)
        return userToken
    } 

    function toRegis() {
        const options = {
            autoConfig: true, 
            debug: false, 
        };
        ReactPixel.init(pixel,options);
        ReactPixel.track(addToCart);
        navigate("/consultations/regis");
    }

    const [cosultData, setPost] = React.useState(null);
    const [config, setConfig] = React.useState(false);

    React.useEffect(() => {
        setIsLoading(true);
        
        const options = {
            autoConfig: true, 
            debug: false, 
        };
        ReactPixel.init(pixel,options);
        ReactPixel.track(viewContent);
        setIsLoading(false);
    }, []);

    if (isLoading){
        return (
            <>
                <LoadingSpinner />
            </>
        )
    }

    return (
        <>
        <div className="consultationlp">
            <div className="logo-header">
                <img src={logo} className="logo-img left-column"></img>
                <h1 className="right-column">Techservant</h1>
                {/* <span>Get your weapons for the real tech worlds</span> */}
            </div>
            <div className="title">
                <h5>
                    Get Your Weapons For The Real Tech World
                </h5>
            </div>
            {/* <div className="header-img"></div> */}
            <div className="header">
                <img src={image1} className="header-img"></img>
            </div>
            
            <div className="sub-title">
                <h3>Dapatkan Roadmap untuk Menjadi Software Engineer di Dunia Tech yang Sesungguhnya !</h3>
                {/* <h3>Evolusi Teknologi Dalam 10 Tahun Terakhir</h3> */}
            </div>
            <div className="container-items">
                <div className="items">
                    <div className="item">
                        <img src={potential} className=""></img>
                    </div>
                    <div className="item">
                        <p>Cek Potensi Kamu Sebagai Software Engineer</p>
                    </div>
                </div>
                <div className="items">
                    <div className="item">
                        <img src={evtech} className=""></img>
                    </div>
                    <div className="item">
                        <p>Pengetahuan Perkembangan Teknologi 10 Tahun Terakhir</p>
                    </div>
                </div>
                <div className="items">
                    <div className="item">
                        <img src={curlevel} className=""></img>
                    </div>
                    <div className="item">
                        <p>Cek Level Kamu Berdasarkan Perkembangan Teknologi</p>
                    </div>
                </div>
                <div className="items">
                    <div className="item">
                        <img src={roadmap} className=""></img>
                    </div>
                    <div className="item">
                        <p>Roadmap Software Engineer Sesuai Kepribadian Kamu</p>
                    </div>
                </div>
            </div>
            <div className="offer">
                <div className="hook">
                    <p>November Special Offer </p>
                    <p>Hanya Untuk 20 Pendaftar Pertama</p>
                </div>
                <div className='myCoursesCard'>
                    <div className='container'>
                        <div className='items' >
                        <div className='content flex'>
                            
                            <div className='text'>
                                <h1>1 on 1 Consultation</h1>
                                <div className='rate'>
                                    <i className='fa fa-star'></i>
                                    <i className='fa fa-star'></i>
                                    <i className='fa fa-star'></i>
                                    <i className='fa fa-star'></i>
                                    <i className='fa fa-star'></i>
                                    <label htmlFor=''>(5.0)</label>
                                </div>
                                <div className='details'>
                                    <span>30 Minutes</span>
                                </div>
                            </div>
                        </div>
                        <div className='price'>
                            <h4 className="crossed-text">
                                <FormatRupiah value="249000"/>
                            </h4>
                            <h5 >
                                <FormatRupiah value="49000"/>
                            </h5>
                        </div>
                        <button className="btn btn-card" onClick={()=>toRegis()}>
                            <small> Daftar Sekarang </small>
                        </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default Consultationlp